import React, { useEffect, useState } from 'react';
import { Brand, Maker, ProductStatus } from '../../../types/typescript-axios';
import { useManager } from "../../contexts/ManagerProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authorizedRequest } from "../../../utils/api-request";
import { useCategories } from "../../contexts/category-provider";

type Props = {
  csvDownload: () => void
}

export function ProductModal({csvDownload}: Props): JSX.Element {
  const manager = useManager()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [showModal, setShowModal] = useState<boolean>(false);

  const [channels, setChannels] = useState<string[]>([])
  const [statuses, setStatuses] = useState<ProductStatus[]>([1, 2])
  const [searchCategoryId, setSearchCategoryId] = useState<number>()
  const [searchMaker, setSearchMaker] = useState<number>()
  const [searchBrand, setSearchBrand] = useState<number>()
  const [searchReleaseDateFrom, setSearchReleaseDateFrom] = useState<string>()
  const [searchReleaseDateTill, setSearchReleaseDateTill] = useState<string>()
  const [searchCreateDateFrom, setSearchCreateDateFrom] = useState<string>()
  const [searchCreateDateTill, setSearchCreateDateTill] = useState<string>()
  const [searchUpdateDateFrom, setSearchUpdateDateFrom] = useState<string>()
  const [searchUpdateDateTill, setSearchUpdateDateTill] = useState<string>()

  const categories = useCategories()
  const [makers, setMakers] = useState<Maker[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  const [allBrands, setAllBrands] = useState<Brand[]>([])

  useEffect(() => {
    async function fetchMakers() {
      const response = await authorizedRequest(manager, 'IndexMakers', {per: 50000})
      if (response.status !== 200) return

      const {data: {makers: responseMakers}} = response
      setMakers(responseMakers.sort((a, b) => a.name > b.name ? 1 : -1))
    }

    async function fetchBrands(makerId?: number) {
      const response = await authorizedRequest(manager, 'IndexBrands', {
        per: 100000
      })
      if (response.status !== 200) return

      const {data: {brands: responseBrands}} = response
      const brands = responseBrands.sort((a, b) => a.name > b.name ? 1 : -1)
      setAllBrands(brands)
      setBrands(brands)
    }

    fetchMakers()
    fetchBrands()
  }, [])

  useEffect(() => {
    if (!searchMaker) return setBrands(allBrands)

    setBrands(allBrands.filter(b => b.maker.id === searchMaker))

  }, [searchMaker])


  useEffect(() => {
    const channels = searchParams.get('channels')?.split(',') || []
    const statuses = searchParams.get('statuses')?.split(',')?.map(s => Number(s)) || []
    const categoryId = searchParams.get('category') ? Number(searchParams.get('category')) : undefined
    const makerId = searchParams.get('maker') ? Number(searchParams.get('maker')) : undefined
    const brandId = searchParams.get('brand') ? Number(searchParams.get('brand')) : undefined
    const releaseDateFrom = searchParams.get('release-from') || undefined
    const releaseDateTill = searchParams.get('release-till') || undefined
    const createDateFrom = searchParams.get('create-from') || undefined
    const createDateTill = searchParams.get('create-till') || undefined
    const updateDateFrom = searchParams.get('update-from') || undefined
    const updateDateTill = searchParams.get('update-till') || undefined
    setChannels(channels);
    setStatuses(statuses as ProductStatus[]);
    setSearchCategoryId(categoryId)
    setSearchMaker(makerId);
    setSearchBrand(brandId);
    setSearchReleaseDateFrom(releaseDateFrom);
    setSearchReleaseDateTill(releaseDateTill)
    setSearchCreateDateFrom(createDateFrom);
    setSearchCreateDateTill(createDateTill)
    setSearchUpdateDateFrom(updateDateFrom);
    setSearchUpdateDateTill(updateDateTill)
  }, [searchParams])

  function toggleStatus(status: ProductStatus) {
    if (statuses.includes(status)) setStatuses(statuses.filter(s => s !== status))
    else setStatuses([...statuses, status])
  }

  function toggleChannel(channel: string) {
    if (channels.includes(channel)) setChannels(channels.filter(ch => ch !== channel))
    else setChannels([...channels, channel])
  }

  async function search() {
    searchParams.delete('page')

    channels?.length > 0 ? searchParams.set('channels', channels.join(',')) : searchParams.delete('channels')
    statuses?.length > 0 ? searchParams.set('statuses', statuses.join(',')) : searchParams.delete('statuses')
    searchCategoryId ? searchParams.set('category', String(searchCategoryId)) : searchParams.delete('category')
    searchMaker ? searchParams.set('maker', String(searchMaker)) : searchParams.delete('maker')
    searchBrand ? searchParams.set('brand', String(searchBrand)) : searchParams.delete('brand')
    searchReleaseDateFrom ? searchParams.set('release-from', String(searchReleaseDateFrom)) : searchParams.delete('release-from')
    searchReleaseDateTill ? searchParams.set('release-till', String(searchReleaseDateTill)) : searchParams.delete('release-till')
    searchCreateDateFrom ? searchParams.set('create-from', String(searchCreateDateFrom)) : searchParams.delete('create-from')
    searchCreateDateTill ? searchParams.set('create-till', String(searchCreateDateTill)) : searchParams.delete('create-till')
    searchUpdateDateFrom ? searchParams.set('update-from', String(searchUpdateDateFrom)) : searchParams.delete('update-from')
    searchUpdateDateTill ? searchParams.set('update-till', String(searchUpdateDateTill)) : searchParams.delete('update-till')

    navigate({
      search: `?${searchParams}`
    })
    setShowModal(false)
  }

  return (
    <>
      <button
        className='blue-btn w-[150px] h-[45px] mr-8'
        onClick={csvDownload}
      >
        CSVダウンロード
      </button>
      <button onClick={() => setShowModal(true)} className='w-[100px] h-[45px] bg-blue-400 duration-200'>
        詳細検索
      </button>
      {
        showModal && (
          <div
            className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black-transparent z-50'>
            <div className='flex flex-col items-center w-[800px] max-h-[80vh] bg-white py-40'>
              <div className='w-[680px]'>
                <div className="mt-3 text-center">
                  <h1
                    className=" font-medium text-blue-400"
                    id="modal-title">
                    商品詳細検索
                  </h1>
                </div>
                <div className='mb-[5px] mx-[20px] flex'>
                  <p className='text-[15px] mr-[20px]'>チャネル</p>
                  <div className='flex items-center mb-[25px]'>
                    {
                      ['デパコス','プチプラ','韓国コスメ','オーガニック'].map((ch, index) => (
                        <div className='flex items-center mr-[25px]'>
                          <input id={`channel-${index}`} type="checkbox" className='w-[15px]'
                                 checked={channels.includes(ch)} onChange={() => toggleChannel(ch)}/>
                          <label htmlFor={`channel-${index}`} className='text-[15px] ml-[8px] w-[90px]'>{ch}</label>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className='mb-[15px] mx-[20px] flex'>
                  <p className='mb-[10px] text-[15px] mr-[35px]'>公開ステータス</p>
                  <div className='flex items-center mb-[25px]'>
                    <div className='flex items-center mr-[25px]'>
                      <input id='status-1' type="checkbox" className='w-[15px]' checked={statuses.includes(1)}
                             onChange={() => toggleStatus(1)}/>
                      <label htmlFor='status-1' className='text-[15px] ml-[8px] w-[60px]'>公開</label>
                    </div>
                    <div className='flex items-center mr-[25px]'>
                      <input id='status-2' type="checkbox" className='w-[15px]' checked={statuses.includes(2)}
                             onChange={() => toggleStatus(2)}/>
                      <label htmlFor='status-2' className='text-[15px] ml-[8px] w-[60px]'>非公開</label>
                    </div>
                    <div className='flex items-center mr-[25px]'>
                      <input id='status-3' type="checkbox" className='w-[15px]' checked={statuses.includes(3)}
                             onChange={() => toggleStatus(3)}/>
                      <label htmlFor='status-3' className='text-[15px] ml-[8px] w-[60px]'>廃止</label>
                    </div>
                  </div>
                </div>
                <div className='flex items-center mb-[15px] mx-[20px]'>
                  <label className='text-[15px] mb-[5px] mr-[80px]'>カテゴリ</label>
                  <select className='w-[370px] h-[35px] shadow-sm'
                          value={searchCategoryId} onChange={(e) => setSearchCategoryId(Number(e.target.value))}>
                    <option>全ての商品</option>
                    {
                      categories?.map((categories, index) => (
                        <option key={index} value={categories.id}>{categories.categoryName}</option>
                      ))
                    }
                  </select>
                </div>
                <div className='flex items-center mb-[15px] mx-[20px]'>
                  <label className='text-[15px] mb-[5px] mr-[80px]'>メーカー</label>
                  <select className='w-[370px] h-[35px] shadow-sm'
                          value={searchMaker} onChange={(e) => setSearchMaker(Number(e.target.value))}>
                    <option>全ての商品</option>
                    {
                      makers?.map((maker, index) => (
                        <option key={index} value={maker.id}>{maker.name}</option>
                      ))
                    }
                  </select>
                </div>
                <div className='flex items-center mb-[25px] mx-[20px]'>
                  <label className='text-[15px] mb-[5px] mr-[80px]'>ブランド</label>
                  <select className='w-[370px] h-[35px] shadow-sm'
                          value={searchBrand} onChange={(e) => setSearchBrand(Number(e.target.value))}>
                    <option>全ての商品</option>
                    {
                      brands?.map((brand, index) => (
                        <option key={index} value={brand.id}>{brand.name}</option>
                      ))
                    }
                  </select>
                </div>
                <div className='mb-[25px] mx-[20px]'>
                  <label className='text-[15px] mb-[5px] mr-[96px]'>発売日</label>
                  <input type="date" className='w-[180px] mr-[10px] h-[35px] shadow-sm'
                         value={(searchReleaseDateFrom)}
                         onChange={(e) => setSearchReleaseDateFrom(e.target.value)}/>
                  <span className=''>〜</span>
                  <input type="date" className='w-[180px] ml-[10px] h-[35px] shadow-sm'
                         value={(searchReleaseDateTill)}
                         onChange={(e) => setSearchReleaseDateTill(e.target.value)}/>
                </div>
                <div className='mb-[25px] mx-[20px]'>
                  <label className='text-[15px] mb-[5px] mr-[96px]'>登録日</label>
                  <input type="date" className='w-[180px] mr-[10px] h-[35px] shadow-sm'
                         value={(searchCreateDateFrom)}
                         onChange={(e) => setSearchCreateDateFrom(e.target.value)}/>
                  <span className=''>〜</span>
                  <input type="date" className='w-[180px] ml-[10px] h-[35px] shadow-sm'
                         value={(searchCreateDateTill)}
                         onChange={(e) => setSearchCreateDateTill(e.target.value)}/>
                </div>
                <div className='mb-[15px] mx-[20px]'>
                  <label className='text-[15px] mb-[5px] mr-[96px]'>更新日</label>
                  <input type="date" className='w-[180px] mr-[10px] h-[35px] shadow-sm'
                         value={(searchUpdateDateFrom)}
                         onChange={(e) => setSearchUpdateDateFrom(e.target.value)}/>
                  <span className=''>〜</span>
                  <input type="date" className='w-[180px] ml-[10px] h-[35px] shadow-sm'
                         value={(searchUpdateDateTill)}
                         onChange={(e) => setSearchUpdateDateTill(e.target.value)}/>
                </div>
                <div className="px-4 py-1 flex justify-center">
                  <button
                    type="button"
                    onClick={search}
                    className="mt-3 w-[200px] h-[45px] inline-flex justify-center rounded-[30px] bg-blue-400
                    shadow-sm px-4 py-2 text-white hover:bg-blue-500 font-bold duration-300 mr-[20px]"
                  >
                    この条件で検索する
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-[200px] h-[45px] inline-flex justify-center rounded-[30px] border border-blue-400 shadow-sm px-4 py-2 text-blue-400 hover:bg-blue-400 hover:text-white font-bold duration-300"
                    onClick={() => setShowModal(false)}>
                    戻る
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
